@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountOrderTabs {
    display: flex;
    margin-block-start: 13px;
    transform: translateY(1px);
    z-index: 1;

    @include desktop {
        flex-direction: row;
    }

    &-Wrapper {
        @include mobile {
            padding: 0;
        }

        .ExpandableContent-Heading {
            text-transform: uppercase;
        }

        .ExpandableContent {
            border-block-end: 0;
        }

        &> :first-child {
            border-block-start: 0;
        }
    }
}
