@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable number-max-precision */
/* stylelint-disable no-missing-end-of-source-newline */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    @include desktop {
        --my-account-tab-list-background: #{$white};
        --my-account-tab-list-separator-color: rgba(10, 9, 3, 0.16);
    }
}

.MyAccountTabList {
    @include desktop {
        background-color: var(--my-account-tab-list-background);
        border: var(--my-account-content-border);
        padding: 12px 0;
    }

    &-ExpandableContentContent {
        max-height: unset;
        overflow: unset;
        opacity: unset;

        @include desktop {
            margin-block-start: 0;
        }

        @include mobile {
            .MyAccountTabListItem_isActive {
                display: unset;
            }

            li {
                border-bottom: 0.5px solid #DDDDDD;
                margin-inline-start: 16px;
                margin-inline-end: 16px;
            }
        }

        &_isContentExpanded {
            @include mobile {
                padding-block-start: 0;
                padding-block-end: 14px;
                padding-inline: 14px;
            }
        }
    }

    &-ExpandableContentButton {
        padding-inline: 16px;
        display: none;

        &::before,
        &::after {
            inset-inline-end: 24px;

            @include mobile {
                inset-inline-end: 28px;
            }
        }
    }

    &-Separator {
        width: 90%;
        margin: auto;
        height: 1px;
        background-color: var(--my-account-tab-list-separator-color);
    }

    &-UserDetails {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        margin-block-end: 3rem;
        
        .Name {
            font-size: 20px;
            font-weight: 500;
            max-width: 245px;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis; 
        }

        .Phone {
            font-size: 14px;
            color: #959595;
        }
    }

    .GreenPoints {
        padding: 5px 14px;
        background: #e6d93b;
        letter-spacing: 1px;
        font-size: 12px;
        font-weight: 500;
        width: fit-content;
    }

    .Menu-ProfileSection .Profile span {
        @include mobile {
            display: unset;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            max-width: 250px;
        }
    }
}