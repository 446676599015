@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountMyTicketView {
    .MyAccount {
        &-HeadingSection {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-block-end: 20px;

            .button-back {
                background-color: #fff;
                color: #739536;
                padding-inline: 60px;

                @include mobile {
                    padding-inline: unset;
                    width: 50%;
                }
            }
        }

        &-Heading {
            margin-block-end: unset;
        }
    }

    &-form {
        box-shadow: 4px 5px 16px rgba(0, 0, 0, 0.06);
        padding: 30px;
        margin-block-start: 30px;
        border: 1px solid #f5f5f5;

        form {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            align-items: flex-end;

            .Field-Wrapper {
                width: 100%;
            }
            
            textarea {
                width: 100%;
            }
        }
    }

    &-ticket-card {
        display: flex;
        padding: 20px;
        column-gap: 20px;
        box-shadow: 4px 5px 16px rgba(0, 0, 0, 0.06);
        margin-block-start: 30px;

        .profile {
            &-img {
                .image {
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                }
            }

            &-details {
                width: 80%;
                display: flex;
                flex-direction: column;
                row-gap: 10px;

                .name {
                    font-size: 18px;

                    .created_at {
                        font-size: 14px;
                        color: #bbbbbb;
                        margin-inline-start: 10px;
                    }
                }

                .attachments {
                    display: grid;
                    grid-template-columns: repeat(5, 1fr);
                    grid-gap: 30px;
                }
            }
        }
    }
}
