@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountOrderInformation {
    &-InformationRow {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;

        @include  mobile {
            grid-template-columns: 1fr;
            grid-row-gap: 2rem;
        }
    }

    &-Information {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex-wrap: wrap;
        row-gap: 3rem;

        @include desktop {
            flex-direction: row;
            row-gap: 4rem;
        }
    }

    &-Name {
        font-weight: 700;
        display: flex;
    }

    &-lastname {
        margin-inline-start: 10px;
    }

    &-contactDetails {
        display: flex;
    }

    &-region {
        margin-inline-start: 10px;
    }

    &-city {
        margin-inline-start: 10px;
    }

    &-AddressDetails {
        display: flex;
    }

    &-emailDetails {
        display: flex;
    }

    &-Title {
        font-size: 22px;
        padding-block-end: 10px;
        margin-block-end: 25px;
        border-block-end: var(--my-account-content-border);
    }

    &-AddressWrapper {
        margin-block-start: 20px;
    }

    &-Address {
        .Address-Action {
            display: none;
        }

        .KeyValueTable {
            margin-block-end: 0;

            th,
            td {
                word-break: break-word;

                @include desktop {
                    padding-inline-start: 0;
                }
            }

            tbody {
                tr {
                    &:first-of-type {
                        td,
                        th {
                            padding-block-start: 0;
                        }
                    }
                }
            }
        }

        .AddressContainer {
            .FullName {
                display: flex;
                column-gap: 5px;
            }
        }
    }


    &-postcode {
        margin-inline-start: 10px;
    }

    &-shippingaddresscontent {
        display: grid;
        grid-gap: 16px;
    }


    &-Column {
        font-size: 14px;

        &_type {
            &_billingAddress,
            &_shippingAddress {
                @include desktop {
                    margin-inline-end: 25px;
                }
            }
        }

        @include desktop {
            margin-block-end: 0;
        }

        @include  mobile {
            padding: 1rem 1rem 1.5rem 1rem;
            border: 1px solid #d4d4d4;
        }
    }

    &-ColumnTitle {
        font-size: 16px;
        margin-block-end: 15px;

        span {
            font-weight: 500;
        }

        @include mobile {
            border-radius: 2px;
            padding: 10px 0;
        }
    }

    &-PaymentMethod {
        display: flex;
        flex-direction: column;

        span:not(:last-child) {
            margin-block-end: 10px;
        }

        @include mobile {
            padding-inline-start: 0;
        }
    }
}
