@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountInformation {
    &-Wrapper {
        .Form {
            .FieldForm {
                &-Remote {
                    margin-block-end: 24px;
                    margin-block-start: 36px;
                }

                &-Section {
                    @include desktop {
                        grid-template-columns: 2fr 2fr 2fr;
                        display: grid;
                        grid-gap: 20px;
                    }
                }
            }
        }
    }

    &-Submit {
        min-width: 110px;
    }

    &-Actions {
        display: flex;
        gap: 3rem;
        border-block-start: 1px solid #e0e0e0;
        padding-block-start: 20px;
        align-items: center;

        @include desktop {
            margin-block-start: 30px;
        }

        .Cancel {
            border: 1px solid var(--color-black);
            color: var(--color-black);
            background-color: transparent;
            padding: 14px;
        }
    }
}
